<template>
  <div>
    <!-- this form use for role table,user group table,group table, tag table , status table , project type -->
    <validation-observer ref="groupObserver">
      <b-sidebar
        id="addgroup"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Group
        </h3>
        <div class="line user-underline" />

        <!--Group name start-->
        <b-form-group
          label="group name"
          label-for="groupname"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required|min:3|max:40"
          >
            <b-form-input
              id="groupname"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              placeholder="Group Name"
              name="groupname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Group name end-->
        <!-- Group color -->
        <b-form-group
          label="group color"
          label-for="Group  color"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Color"
            mode="passive"
            rules="required"
          >
            <b-input-group class="color-picker-input">
              <b-form-input
                id="groupcolor"
                v-model="groupColor"
                type="text"
                name="groupcolor"
                placeholder="Select any color"
              />
              <b-input-group-append is-text>
                <input
                  v-model="groupColor"
                  type="color"
                  name="projectcolor"
                  class="color-picker"
                >
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Group Member -->
        <b-form-group
          label="Group Members "
          label-for="group_manage"
          class="required-form-filed"
        >
          <validation-provider
            #default="{ errors }"
            name="Group Members"
            rules="required"
          >
            <v-select
              id="tags"
              v-model="group_members"
              hide-details
              multiple
              name="tags"
              label="name"
              :options="users"
              :reduce="(user) => user.id"
              class="select-tags-timesheet2"
              placeholder="Select Any Member"
              :close-on-select="false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Group Color End-->
        <template #footer>
          <!-- form footer start-->
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData"
            >
              Clear
            </button>
          </div>
          <!-- form footer end-->
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eventBus } from '@/main'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      id: null,
      name: null,
      groupColor: null,
      btnLoader: false,
      users: [],
      group_members: [],
    }
  },
  mounted() {
    eventBus.$on('edit-group', data => {
      if (data) {
        this.id = data.id
        this.name = data.name
        this.groupColor = `#${data.color}`
        this.group_members = []
        if (data.users && data.users.length) {
          data.users.forEach(element => {
            this.group_members.push(element.id)
          })
        }

        this.$root.$emit('bv::toggle::collapse', 'addgroup')
      }
    })
    this.getUserData()
  },
  destroyed() {
    eventBus.$off('edit-group')
  },
  methods: {
    /**
     *  clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.name = null
      this.groupColor = null
      this.group_members = []
      this.$refs.groupObserver.reset()
    },
    /**
     *  check the form validation
     * @returns if success true then call saveGroupDtl Method
     */
    validationForm() {
      this.$refs.groupObserver.validate().then(success => {
        if (success) {
          this.saveGroupDtl()
        }
      })
    },
    /**
     * Add & Update the GroupTableForm details & call Group listing in grouptable
     * @emits groupList
     * @returns Group detail add & update
     */
    async saveGroupDtl() {
      this.btnLoader = true
      const color = this.groupColor.substring(1)
      const input = {
        name: this.name,
        color,
        user_ids: this.group_members,
      }
      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/group/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/group/create',
          input,
          true,
        )
      }

      if (response && response.status == 200) {
        this.$root.$emit('bv::toggle::collapse', 'addgroup')
        this.$emit('groupList', 1)
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },
    /**
     *  Clear form details
     */
    clearData() {
      this.name = null
      this.group_members = []
      this.groupColor = null
      this.$refs.groupObserver.reset()
    },
    /**
     * Get users data
     * @returns users for manage_by & Reporting field
     */
    async getUserData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.users.map(user => {
          user.name = `${user.name} ${user.last_name} `
          return user
        })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import "../../../assets/scss/variables/variables";
.custom-radio label {
  margin-top: unset !important;
}
.color-picker-input {
  .color-picker {
    height: 36px;
    width: 60px;
    border: transparent;
    background: transparent;
  }
  .input-group-text {
    padding: 0;
  }
  .form-control {
    border-right: none;
  }
}

.select-tags-timesheet2 {
  .vs__selected-options {
    gap: 5px !important;
    .vs__selected {
      font-size: 1.75 * $rem !important;
      background-color: $primary-color;
      color: $white;
      padding: 5px !important;
      border: transparent;
      text-transform: capitalize;

      svg {
        fill: $white;
      }
    }
  }
  .vs__selected {
    background-color: #2178fb;
    color: #ffff;
    border: transparent;
    text-transform: capitalize;
  }
  .vs__deselect {
    fill: #ffff;
  }
  .vs__search {
    &::placeholder {
      color: #b9b9c3;
    }
  }
  .vs__selected-options {
    padding: 2px 2px;
  }
  .vs__dropdown-menu {
    // scroll-behavior: smooth;
    // scrollbar-width: thin;
    text-transform: capitalize;
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: transparent;

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 20px;
      }
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 20px;
    }
    li {
      &:hover {
        background-color: #2178fb;
        color: #ffff;
      }
    }
  }
}
</style>
